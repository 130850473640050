<template>
  <div>
    <div>
      <HeaderPublic><GTM></GTM></HeaderPublic>
    </div>
    <div id="registration-link-error-content" class="container-sm mt-sm-5">
      <div id="registration-link-error-form">
        <h1 class="change-password-header">
          The activation link has expired or is invalid
        </h1>
        <div class="link-error-message">
          <div>
            Attempt to
            <router-link
              id="lgn"
              :to="{ path: `/${lang_info.lang_code}/login` }"
              >Login
            </router-link>
          </div>

          <div>
            If you are having trouble logging in, please
            <b
              ><a
                href="mailto:TexasOnCourseSupport@highered.texas.gov?subject=Tomorrow Ready Texas Support Request"
                class="contact-link"
              >
                Contact Support</a
              ></b
            >
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  title: "Link Error | Tomorrow Ready Texas",
  data() {
    return {
      lang_stanza_key: "registration_link_error",
      lang_stanza: {},
      lang_info: {},
    };
  },
  methods: {
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    document.body.style.backgroundColor = "#F5F7FA !important";
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
#registration-link-error-content {
  border-radius: 10px;
  height: 519px;
  max-width: 664px;
  margin-top: 26px !important;
  margin-bottom: 232px;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.1);
  border: none !important;
}

#registration-link-error-form {
  height: 519px;
  max-width: 500px;
  padding: 72px 16px 252px 16px;
  margin-left: auto;
  margin-right: auto;
}

.container-sm {
  padding-left: 16px;
  padding-right: 16px;
}

h1.change-password-header {
  text-align: left;
  margin-bottom: 37px;
}

.link-error-message {
  text-align: left;
  color: #3a3a3a;
  font-family: Lato;
  font-size: 18px;
}

.link-error-message div:nth-child(1) {
  margin-bottom: 21px;
}

.contact-link,
#lgn {
  color: #1a6edb;
  margin-left: 2px;
}
</style>
