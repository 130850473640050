<template>
  <div>
    <HeaderPublic></HeaderPublic>
    <div id="login-content" class="container-sm mt-sm-5">
      <div id="loginForm">
        <div class="col-2"></div>
        <div id="center" class="col-8">
          <div
            id="Alert"
            tabindex="-1"
            v-if="alert.message"
            :class="'alert ' + alert.type"
          >
            {{ alert.message }}
          </div>
          <h1 class="login-header">{{ lang_stanza.login_to_account }}</h1>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)" novalidate>
              <div class="form-group">
                <label for="email">{{ lang_stanza.email }}</label>
                <ValidationProvider
                  mode="lazy"
                  rules="err_invalid_email|err_email_required"
                  v-slot="{ errors }"
                >
                  <input
                    type="email"
                    v-model="email"
                    name="email"
                    id="email"
                    class="form-control"
                    autofocus
                  />
                  <span class="error">{{ langLookup(errors[0]) }}</span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label for="password">{{ lang_stanza.password }}</label>
                <ValidationProvider
                  mode="lazy"
                  rules="err_password_required"
                  v-slot="{ errors }"
                >
                  <input
                    type="password"
                    v-model="password"
                    name="password"
                    id="password"
                    class="form-control"
                  />
                  <span class="error">{{ langLookup(errors[0]) }}</span>
                </ValidationProvider>
              </div>
              <button
                type="submit"
                class="btn btn-primary login-button"
                :disabled="status.loggingIn"
              >
                <span v-show="!loading">{{ lang_stanza.login }}</span>
                <div
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                >
                  <span class="sr-only">Loading...</span>
                </div>
              </button>
            </form>
          </ValidationObserver>
          <div class="account-links">
            <router-link
              variant="link"
              :to="{ path: `/${lang_info.lang_code}/password-reset` }"
              class="forgot-password-btn"
              >{{ lang_stanza.forgot_password }}
            </router-link>
            <router-link
              variant="link"
              :to="{ path: `/${lang_info.lang_code}/registration` }"
              class="create-account-btn"
              >{{ lang_stanza.create_account }}
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

},
<script>
import { mapActions, mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { validation } from "../_helpers/validation";
import { router, sessionStorage } from "../_helpers";
import dateformat from "dateformat";
export default {
  title: "Log in | Tomorrow Ready Texas",
  name: "Login",
  data() {
    return {
      email: "",
      password: "",
      submitted: false,
      loading: false,
      lang_stanza_key: "login",
      lang_stanza: {},
      lang_info: {},
    };
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    if (this.lang_info.lang_code === "en") {
      document.documentElement.setAttribute("lang", "en");
    } else {
      document.documentElement.setAttribute("lang", "es");
    }

    document.body.style.backgroundColor = "#FAFAFA";
  },
  updated() {
    // 2022-01-29. Commenting this out for review -- generates console error on undefined focus method.
    // document.getElementById("Alert").focus();
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    ...mapActions("registration", ["loginUser"]),
    ...mapActions("preferences", ["getUserGradePathwayList"]),
    ...mapActions("language", [
      "persistLangChoice",
      "getLangInfo",
      "getLangStanza",
    ]),
    ...mapActions("account", [
      "createUserProfile",
      "getUserProfileByOidc",
      "persistDateLastLogin",
    ]),
    ...mapActions({
      clearAlert: "alert/clear",
    }),
    langLookup(key) {
      if (!key) {
        return null;
      }
      let lang_value = this.lang_stanza[key];
      if (!lang_value) {
        lang_value = key;
      }
      return lang_value;
    },
    async loginActions() {
      //Checks for existing DB User Profile Entry
      //Checks to see if entry is active and routes accordingly
      // Session storage for user profile should have been created on login
      let sessionUserProfile = await sessionStorage.decodeUserSession();
      if (!sessionUserProfile || !sessionUserProfile.sub) {
        // TODO Should display error message indicating login failed. Routing back to login for now
        this.loading = false;
        return;
      }
      const oidc_id = sessionUserProfile.sub;
      let confirmed_at = sessionUserProfile.confirmed_at;
      let is_account_active = false;
      if (confirmed_at) {
        is_account_active = true;
      }
      // Check for user profile in database
      await this.getUserProfileByOidc(oidc_id);
      // User profile not found in database, add it.
      if (!this.userProfile) {
        let save_email = this.email;
        this.password = "";
        let newProfile = {
          email: sessionUserProfile.email,
          oidc_id: oidc_id,
          first_name: sessionUserProfile.given_name,
          last_name: sessionUserProfile.family_name,
          is_active: is_account_active,
          is_disability_interest: false,
          is_tos_acceptance: true,
          is_email_sub: false,
          lang_code: this.lang_info["lang_code"],
        };
        await this.createUserProfile(newProfile);
        let now = new Date();
        let ts_last_login = dateformat(now, "yyyy-mm-dd HH:MM:ss.l");
        let packet_dict = {
          oidc_id: sessionUserProfile.sub,
          ts_last_login: ts_last_login,
        };
        await this.persistDateLastLogin(packet_dict);
        await this.getUserProfileByOidc(oidc_id);
        router.push(`/${this.lang_info.lang_code}/onboarding`);
      } else {
        let now = new Date();
        let ts_last_login = dateformat(now, "yyyy-mm-dd HH:MM:ss.l");

        let packet_dict = {
          oidc_id: sessionUserProfile.sub,
          ts_last_login: ts_last_login,
        };
        await this.persistDateLastLogin(packet_dict);

        packet_dict = {
          oidc_id: this.userProfile.oidc_id,
          lang_code: this.lang_info["lang_code"],
          ts_update: ts_last_login,
        };
        await this.persistLangChoice(packet_dict);

        await this.getUserGradePathwayList({
          oidc_id: oidc_id,
          lang_code: this.lang_info.lang_code,
        });
        router.push(`/${this.lang_info.lang_code}/home`);
      }
    },
    async onSubmit() {
      this.submitted = true;
      this.loading = true;
      const { email, password } = this;
      if (email && password) {
        await this.loginUser({ email, password });
        await this.loginActions();
      }
    },
  },
  computed: {
    ...mapState("registration", ["status", "user"]),
    ...mapState("preferences", ["user_grade_pathway_list"]),
    ...mapState("account", ["userProfile", "oidc_id"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
};
</script>

<style lang="scss" scoped>
.login-buttons {
  margin-top: 2rem;
}
#login {
  margin: 20px;
  background: rgb(255, 94, 0);
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 50px;
}
#login-header {
  margin-top: 1rem;
  margin-bottom: 2rem;
}
#account-links {
  margin-top: 1rem;
  margin-bottom: -2rem;
}
#login-content {
  border-radius: 10px;
  margin-top: 116px !important;
}
#center {
  margin-right: auto;
  margin-left: auto;
  max-width: 450px;
}
.container-sm {
  max-width: 584px;
  background-color: #fff;
  margin-bottom: 120px;
}
#loginForm {
  max-width: 584px !important;
  min-height: 574px !important;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 50px;
  background-color: #fff;
}
.login-header {
  margin-bottom: 32px;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
  text-align: left;
  padding-top: 64px;
}
.form-group > label {
  display: block;
  text-align: left;
}

label[for="email"] {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 12px;
}

label[for="password"] {
  margin-top: 32px !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #333333;
  margin-bottom: 12px;
}
.form-control {
  box-sizing: border-box;
  height: 64px;
  max-width: 448px;
  border: 1px solid #7a7a7a;
  border-radius: 4px;
  background-color: #ffffff;
}
.form-control:focus {
  box-shadow: none;
  border-color: #7a7a7a;
}
.form-control:hover {
  border-color: #1a6edb;
}
.error {
  float: left;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fc4852;
  position: static;
}
input.form-control:invalid {
  border-color: red !important;
}
.login-button {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-top: 32px !important;
  margin-bottom: 31px !important;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 64px;
  gap: 8px;
  width: 180px;
  height: 60px;
  background: #c24c21;
  border: 2px solid #c24c21;
  border-radius: 16px;
}
.login-button:hover {
  background: #ffffff;
  border: 2px solid #c24c21;
  color: #c24c21;
}
.login-button:active {
  background: #a9431d;
  border: 2px solid #a9431d;
}
.login-button:focus {
  box-shadow: none !important;
}
.vl {
  border-left: 1px solid lightgray;
  height: 150px;
}
.forgot-password-btn {
  margin-top: 40px;
  padding: 10px;
  padding-right: 50px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #047ac3;
}
.create-account-btn {
  margin-top: 40px;
  padding: 10px;
  padding-left: 42px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #047ac3;
  width: 147.5px;
}

#Alert {
  top: 75px;
}

.col-8 {
  padding: 0;
}

@media (min-width: 376px) and (max-width: 600px) {
  #loginForm {
    margin-top: 100px;
  }

  #login-content {
    padding-left: 16px;
    padding-right: 16px;
  }

  .container-sm {
    background-color: transparent;
  }

  #account-links {
    text-align: center;
  }
  .forgot-password-btn {
    display: inline;
    white-space: nowrap;
    padding: 0px;
    padding-right: 97px;
  }
  .create-account-btn {
    display: inline;
    white-space: nowrap;
    padding: 0px;
    padding-left: 0px;
    border-left: none;
  }
}

@media (min-width: 487px) and (max-width: 768px) {
  #loginForm {
    margin-top: 100px;
  }

  #login-content {
    padding-left: 16px;
    padding-right: 16px;
  }

  .container-sm {
    background-color: transparent;
  }
}

@media (min-width: 376px) and (max-width: 486px) {
  #loginForm {
    margin-top: 24px;
    height: 540px !important;
  }

  #login-content {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 24px !important;
  }

  .container-sm {
    background-color: transparent;
    margin-bottom: 64px;
  }

  .login-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-right: 0px;
    margin-top: 32px;
    padding-top: 32px;
  }

  label[for="email"] {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .login-button {
    margin-top: 16px !important;
  }

  .forgot-password-btn {
    display: grid;
    padding-right: 0px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
}

@media (max-width: 375px) {
  .container-sm {
    margin-right: 0px;
    margin-left: 16px;
    max-width: 344px;
    margin-bottom: 64px;
  }

  #login-content {
    margin-top: 24px !important;
  }

  #loginForm {
    max-width: 344px !important;
    margin-top: 24px;
    padding: 0;
  }
  #center {
    margin-right: auto;
    margin-left: auto;
  }
  .login-header {
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    margin-right: 0px;
    white-space: nowrap;
    margin-top: 32px;
    padding-top: 32px;
  }

  label[for="email"] {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }

  .login-button {
    margin-top: 16px !important;
  }

  #account-links {
    text-align: center;
  }
  .forgot-password-btn {
    display: grid;
    padding-right: 0px;
    margin-top: 32px;
    margin-bottom: 16px;
  }
  .create-account-btn {
    display: inline;
    white-space: nowrap;
    padding: 0px;
    padding-left: 10px;
    border-left: none;
  }

  .col-8 {
    max-width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
}
</style>
