<template>
  <div>
    <HeaderPublic></HeaderPublic>
    <div id="TOS-content" class="container-sm mt-sm-5">
      <div id="TOS">
        <div class="col-2"></div>
        <div id="center" class="col-8">
          <h1 class="TOS-header">{{ lang_stanza.title }}</h1>
          <br />
          <br />
          <p>
            Please read these Terms of Service ("Terms", "Terms of Service")
            carefully before using the https://texasoncourse.org website, the
            https://www.middlegalaxy.org/ website, the Middle Galaxy mobile
            applications and/or the MapMyGrad tool (the "Services") operated by
            The University of Texas at Austin (UT Austin) on behalf of the
            Office of Strategy and Policy Texas OnCourse ("us", "we", or "our").
            Your access to and use of the Services is conditioned on your
            acceptance of and compliance with these Terms. These Terms apply to
            all visitors, users and others who access or use the Service. By
            accessing or using the Services you agree to be bound by these
            Terms. If you disagree with any part of the terms then you may not
            access the Services, and should send an e-mail to
            support@texasoncourse.org requesting cancellation of your account.
            Definition of Data Data include all Personally Identifiable
            Information (PII) and other non-­public information. Data include,
            but are not limited to, student data, metadata, and user content.
            Marketing and Advertising We will not use any data to advertise or
            market to students or their parents. Advertising or marketing may be
            directed to your school or district only if student information is
            properly de-identified. Data Mining We will not mine Data for the
            purpose of advertising or marketing to students or their parents.
            Termination We may terminate or suspend access to our Services
            immediately, without prior notice or liability, for any reason
            whatsoever, including without limitation if you breach these Terms
            or Service. All provisions of the Terms which, by their nature,
            should survive termination shall survive termination, including,
            without limitation, ownership provisions, warranty disclaimers,
            indemnity and limitations of liability. Links To Other Web Sites Our
            Service may contain links to third-party web sites or services that
            are not owned or controlled by us or UT Austin. We have no control
            over, and assume no responsibility for the content, privacy
            policies, or practices of any third-party web sites or services. You
            further acknowledge and agree that we shall not be responsible or
            liable, directly or indirectly, for any damage or loss caused or
            alleged to be caused by or in connection with use of or reliance on
            any such content, goods or services available on or through any such
            web sites or services. Data Collection and Data Sharing The Services
            may collect data on users, such as frequency of use, duration of
            use, and engagement with specific components and functionality of
            the Services. Collecting and analyzing this data is necessary for us
            to understand how students and educators are using the resources, to
            improve their efficacy, and to develop training materials for
            educators implementing the resources. We may also collect personally
            identifiable information (PII) on students, such as names and email
            addresses, that may allow us to track individual student’s
            engagement with the Services over time. We may link the
            student-level data generated through the instructional resources
            with other data sources, including, but not limited to, data from
            the Texas Education Research Center (TERC) at UT-Austin. TERC
            maintains Texas’ longitudinal student data system that connects K12,
            postsecondary, and workforce data. We may link data collected
            through the Services with TERC data by providing student-level data,
            including PII, to the Texas Education Agency (TEA) or the Texas
            Higher Education Coordinating Board (THECB). TEA or THECB will then
            replace students’ PII with a unique identification number that is
            common across TERC datasets and upload this data to TERC. HB18
            authorized Texas OnCourse to access data maintained by TERC for
            purposes of this evaluation. Texas OnCourse has created a
            Confidential Data Control Plan (CDCP) in compliance with UT Austin’s
            Information Security Office’s (ISO) policies for transferring,
            storing, and accessing confidential data. All student-level data
            collected through the instructional resources will be protected and
            secured in compliance with ISO policies in addition to student
            privacy and confidentiality regulations, including FERPA. A copy of
            the CDCP can be made available to District or Campus upon request.
            If you have any questions or concerns about the evaluation or use of
            your data, please contact Texas OnCourse by e-mail at
            Info@texasoncourse.org. Ownership of Intellectual Property UT Austin
            shall own all Intellectual Property Rights in or relating to the
            Services. Intellectual Property Rights means any rights or titles to
            inventions, discoveries, concepts, methods, processes, data, trade
            secrets, branding, trademarks, copyrights, computer programs and
            related documentation, works of authorship fixed in a medium of
            expression, or mask works, whether or not patentable, copyrightable,
            eligible for registration as a trademark, or subject to mask work
            rights or other similar statutory rights, as well as applications
            for any such rights which are created or reduced to deliver the
            Service. Trademark Information The names and marks “The University
            of Texas at Austin” and “UT Austin” and all associated logos and
            trademarks are the sole and exclusive property of The Board of
            Regents of the University of Texas System and may not be used by you
            except as permitted by applicable U.S. and international trademark
            law. There may be other protected and names and marks used on this
            website similarly protected by U.S. and international law. Venue;
            Governing Law Travis County, Texas, will be the proper place of
            venue for suit on or in respect of the Agreement. The Agreement and
            all of the rights and obligations of the parties hereto, and all of
            the terms and conditions hereof will be construed, interpreted, and
            applied in accordance with, and governed by, and enforced under the
            laws of the State of Texas. Indemnification YOU AGREE TO INDEMNIFY,
            HOLD HARMLESS AND DEFEND THE UNIVERSITY OF TEXAS SYSTEM, THE
            UNIVERSITY OF TEXAS AT AUSTIN, ITS REGENTS, OFFICERS, AGENTS,
            EMPLOYEES, AND LICENSORS (“UT PARTIES”), FROM AND AGAINST ALL
            LIABILITIES, DAMAGES, CLAIMS, DEMANDS, ACTIONS (LEGAL OR EQUITABLE),
            AND COSTS AND EXPENSES (INCLUDING ATTORNEYS’ FEES INCURRED IN
            INVESTIGATING, DEFENDING OR SETTLING ANY OF THE FOREGOING CLAIMS),
            OF ANY KIND OR NATURE, ARISING FROM OR RELATED IN ANY WAY TO YOUR
            USE OF THE SERVICE AND THAT ARE CAUSED IN WHOLE OR IN PART BY ANY
            NEGLIGENT ACT, NEGLIGENT OMISSION OR WILLFUL MISCONDUCT OF YOU OR
            ANYONE FOR WHOSE ACTS YOU MAY BE LIABLE. THE PROVISIONS OF THIS
            SECTION WILL NOT BE CONSTRUED TO ELIMINATE OR REDUCE ANY OTHER
            INDEMNIFICATION OR RIGHT WHICH UT PARTIES HAVE BY LAW OR EQUITY. UT
            PARTIES WILL BE ENTITLED TO BE REPRESENTED BY COUNSEL THEY SELECT AT
            THEIR OWN EXPENSE. IN ADDITION, YOU WILL INDEMNIFY, HOLD HARMLESS
            AND DEFEND UT PARTIES FROM AND AGAINST ALL CLAIMS ARISING FROM
            INFRINGEMENT OR ALLEGED INFRINGEMENT OF ANY INTELLECTUAL PROPERTY
            RIGHT ARISING BY OR OUT OF YOUR USE OF THE SERVICE. Limitation of
            Liability To the fullest extent permitted by applicable law, under
            no circumstances, including, but not limited to, negligence, shall
            we be liable for any direct, indirect, special, incidental or
            consequential damages, including, but not limited to, loss of data
            or profit, arising out of the use, or inability to use, the
            materials on the site, even if we have been advised of the
            possibility of such damages. If your use of materials from the
            services results in the need for servicing, repair or correction of
            equipment or data, you assume any and all costs thereof. Some states
            do not allow the exclusion or limitation of incidental or
            consequential damages, so the above limitation or exclusion may not
            apply to you. Changes We reserve to right to modify and/or correct
            this agreement at any time; your continued use of these Services
            implies agreement with the modified terms. Contact Us If you have
            any questions about these Terms, please contact us at
            Info@texasoncourse.org.
          </p>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { min_val, validation } from "../_helpers/validation";
import VueRecaptcha from "vue-recaptcha";

// import settings from "/ps_settings/config.json";

export default {
  title: "Terms of Service | Tomorrow Ready Texas",
  name: "TermsOfService",
  data() {
    return {
      lang_stanza_key: "tos",
      lang_stanza: {},
      lang_info: {},
    };
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });
  },
  methods: {
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
  },
};
</script>

<style lang="scss">
.col-8 {
  max-width: 100%;
}

#TOS h1 {
  text-align: left;
  color: #030303;
  font-family: Merriweather;
  font-style: normal;
  font-weight: 700;
}

#TOS p {
  text-align: left;
  color: #3a3a3a;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
}
</style>