<template>
    <div id="confirmation-content"  class="container-sm mt-sm-5">
        <div id="confirmation">
            <div class="col-2"></div>
            <div id="center" class="col-8">
                <h1 class="confirmation-header">Registration did not succeed</h1>
                <div class="confirmation-message">
                    <b>Error!</b>
                    <div style="margin-bottom: 26px">Your account was not created.</div>

                    <div>Please try again.  If this problem continues, please <b><a href="mailto:TexasOnCourseSupport@highered.texas.gov?subject=Tomorrow Ready Texas Support Request" class="contact-link"> Contact us</a></b></div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
  async created() {

    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      "lang_code": this.lang_info["lang_code"],
      "lang_stanza_key": this.lang_stanza_key
    });

    document.body.style.backgroundColor = "#F5F7FA";
  },
}
</script>

<style lang="scss" scoped>
#confirmation-content {
  border-radius: 10px;
}

#center {
  margin-right: auto;
  margin-left: auto;
}

#confirmation {
  min-width: 664px !important;
  min-height: 510px !important;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
}

.confirmation-header {
  margin-bottom: 39px;
  font-size: 40px;
  font-weight: bold;
  float: left;
  margin-right: 38px;
  margin-top: 69px;
  white-space: nowrap;
  color: #000000;
}

.confirmation-message {
  text-align: left;
  font-family: Lato;
  font-size: 18px;
  line-height: 28px;
  color:#666666;
}

.confirmation-message > b:nth-child(1) {
  color: #00B258;
}

.contact-link {
    color: #1a6edb;
    margin-left: 2px;
}
</style>
