<template>
  <div>
    <HeaderPublic><GTM></GTM></HeaderPublic>
    <div id="registration-content" class="container-sm mt-sm-5">
      <div id="registration-form">
        <div
          ref="Alert"
          id="Alert"
          tabindex="-1"
          v-if="alert.message"
          :class="'alert ' + alert.type"
        >
          {{ alert.message }}
        </div>
        <div class="col-2"></div>
        <div id="center">
          <div class="registration-setup">
            <h1 id="registration-header">{{ lang_stanza.setup }}</h1>
            <p>
              {{ lang_stanza.gain_full_access }}
            </p>
          </div>

          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <div class="form-group">
                <label for="firstName">{{ lang_stanza.first_name }}</label>
                <ValidationProvider
                  mode="lazy"
                  rules="err_first_name_too_short:2|err_first_name_non_alpha|err_first_name_required"
                  v-slot="{ errors }"
                >
                  <input
                    aria-label="first name"
                    ref="firstName"
                    type="text"
                    v-model="userProfile.firstName"
                    name="first name"
                    class="form-control firstName-input"
                  />
                  <span class="error">{{ langLookup(errors[0]) }}</span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label for="lastName">{{ lang_stanza.last_name }}</label>
                <ValidationProvider
                  mode="lazy"
                  rules="err_last_name_too_short:2|err_last_name_non_alpha|err_last_name_required"
                  v-slot="{ errors }"
                >
                  <input
                    aria-label="last name"
                    type="text"
                    v-model="userProfile.lastName"
                    name="last name"
                    class="form-control"
                  />
                  <span class="error">{{ langLookup(errors[0]) }}</span>
                </ValidationProvider>
              </div>

              <div class="form-group">
                <label for="email">{{ lang_stanza.email }}</label>
                <ValidationProvider
                  mode="lazy"
                  rules="err_invalid_email|err_email_required"
                  v-slot="{ errors }"
                >
                  <input
                    aria-label="email"
                    type="email"
                    v-model="userProfile.email"
                    name="email"
                    class="form-control"
                  />
                  <span class="error">{{ errors[0] }}</span>
                </ValidationProvider>
              </div>

              <ValidationObserver>
                <div class="form-group">
                  <label for="password">{{ lang_stanza.password }}</label>
                  <ValidationProvider
                    :aria="true"
                    name="password"
                    mode="lazy"
                    rules="err_password_too_short:10|err_password_required|err_password_not_password|err_password_not_valid"
                    v-slot="{ errors }"
                  >
                    <input
                      aria-label="password"
                      type="password"
                      v-model="userProfile.password"
                      name="password"
                      class="form-control"
                    />
                    <span class="error">{{ langLookup(errors[0]) }}</span>
                  </ValidationProvider>
                  <!-- <p class="password-val">
                  Password should be a minimum of 8 characters
                </p>  -->
                </div>

                <div class="form-group">
                  <label for="repeatPassword">{{
                    lang_stanza.repeat_password
                  }}</label>
                  <ValidationProvider
                    mode="lazy"
                    rules="err_passwords_not_match:@password|err_repeat_password_required"
                    v-slot="{ errors }"
                  >
                    <input
                      aria-label="repeat password"
                      type="password"
                      v-model="userProfile.confirm_password"
                      name="repeat password"
                      class="form-control confirm-password"
                      id="confirm"
                    />
                    <span class="error">{{ langLookup(errors[0]) }}</span>
                  </ValidationProvider>
                </div>
              </ValidationObserver>

              <!-- <vue-recaptcha
            @verify="markRecaptchaVerified"
            class="recaptcha"
            :sitekey="siteKey"
            :loadRecaptchaScript="true"
            name="reCAPTCHA"
            id="captcha"
          ></vue-recaptcha> -->

              <div class="form-group form-check">
                <ValidationProvider
                  rules="err_tos_required"
                  name="terms of service"
                  v-slot="{ validate, errors }"
                >
                  <input
                    aria-label="terms of service"
                    class="form-check-input"
                    @change="validate"
                    type="checkbox"
                    name="terms of service"
                    id="terms"
                  />
                  <span class="checkmark"></span>

                  <label class="form-check-label">
                    {{ lang_stanza.tos }}
                    <router-link
                      variant="link"
                      :to="{ path: `/${lang_info.lang_code}/terms_of_service` }"
                      target="_blank"
                      id="tos"
                      style="color #1a6edb!important"
                      >{{ lang_stanza.tos_link_text }}</router-link
                    >
                  </label>

                  <div>
                    <span class="error">{{ langLookup(errors[0]) }}</span>
                  </div>
                </ValidationProvider>
              </div>
              <div class="create-btn-div">
                <button
                  class="btn btn-primary create-acct-btn"
                  type="submit"
                  :disabled="status.registering"
                >
                  <span v-show="!loading">
                    {{ lang_stanza.create_account }}</span
                  >
                  <div
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
              <p class="registered-already">
                {{ lang_stanza.already_registered }}
              </p>
              <router-link
                variant="link"
                :to="{ path: `/${lang_info.lang_code}/login` }"
                class="login-btn"
                >{{ lang_stanza.login }}</router-link
              >
            </form>
          </ValidationObserver>

          <div class="col-2"></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { min_val, validation } from "../_helpers/validation";
import VueRecaptcha from "vue-recaptcha";

// import settings from "/ps_settings/config.json";

export default {
  title: "Account registration | Tomorrow Ready Texas",
  name: "Registration",
  data() {
    return {
      userProfile: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirm_password: "",
        is_tos_acceptance: true,
      },
      lang_stanza_key: "reg",
      lang_stanza: {},
      lang_info: {},
      recaptchaVerified: false,
      submitted: false,
      loading: false,
      siteKey: process.env.RECAPTCHA_SECRET,
      // forbidden_substrings: ['declare', 'varchar', 'set', 'exec', 'dirtree', 'schemaLocation', 'objectClass', 'select', 'xml', 'ENTITY', 'SYSTEM', 'master', 'null', 'ping', 'password', 'nslookup'],
    };
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    if (this.lang_info.lang_code === "en") {
      document.documentElement.setAttribute("lang", "en");
    } else {
      document.documentElement.setAttribute("lang", "es");
    }

    document.body.style.backgroundColor = "#FAFAFA";
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    //VueRecaptcha
  },
  mounted() {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js"
    );
    document.head.appendChild(recaptchaScript);
  },

  methods: {
    ...mapActions("registration", ["registerUser"]),
    ...mapActions({
      clearAlert: "alert/clear",
    }),
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    langLookup(key) {
      if (!key) {
        return null;
      }
      let lang_value = this.lang_stanza[key];
      if (!lang_value) {
        lang_value = key;
      }
      return lang_value;
    },
    markRecaptchaVerified() {
      this.recaptchaVerified = true;
    },
    isValid(str) {
      let r = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{10,}$/;
      return r.test(str);
    },
    async onSubmit() {
      // userProfile: {
      //   firstName: "",
      //   lastName: "",
      //   email: "",
      //   password: "",
      //   confirm_password: "",
      //   is_tos_acceptance: true,
      // },

      // if (!this.recaptchaVerified) {
      //   return true;
      // }
      // try{
      //   for(let i=0; i<this.forbidden_substrings.length;i++){
      //     if(this.userProfile.password.includes(this.forbidden_substrings[i]) ||
      //       this.userProfile.firstName.includes(this.forbidden_substrings[i]) ||
      //       this.userProfile.lastName.includes(this.forbidden_substrings[i]) ||
      //       this.userProfile.email.includes(this.forbidden_substrings[i])
      //     ){
      //       throw new Error("Input Invalid")
      //     }
      //   }
      //   if(this.isValid(this.userProfile.password) &&
      //     this.isValid(this.userProfile.firstName) &&
      //     this.isValid(this.userProfile.lastName) &&
      //     this.isValid(this.userProfile.email)
      //   ){
      //     this.submitted = true;
      //     this.loading = true;
      //     this.registerUser(this.userProfile)
      //   }
      //   throw new Error("Input Invalid")
      // } catch(err) {
      // }

      this.submitted = true;
      this.loading = true;
      this.registerUser(this.userProfile);
    },
    // focusInput() {
    //   document.getElementsByClassName('.firstName-input').focus();
    // },
  },
  updated() {
    this.loading = false;

    let alert = this.$refs.Alert;
    if (alert) {
      alert.focus();
    }

    // this.clearAlert();
  },
  computed: {
    ...mapState("registration", ["status"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
};
</script>

<style lang="scss" scoped>
#registration-content {
  border-radius: 16px;
  max-width: 584px;
}

#center {
  margin-left: 64px;
  margin-right: 64px;
}

#registration-form {
  min-height: 720px !important;
  background: #ffffff;
  box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.25);
  border-radius: 16px;
  padding-bottom: 61.5px;
  max-width: 584px;
  margin-top: 116px;
}

#registration-form #Alert {
  top: 40px;
  margin: auto;
}
.registration-setup {
  display: flex;
  flex-direction: column;
  align-content: left;
}

h1#registration-header {
  float: left;
  margin-right: 71px;
  margin-top: 64px;
  margin-bottom: 16px;
  text-align: left;
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
}

.registration-setup p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 32px;
}

p {
  color: #666666;
  font-family: Lato;
  font-size: 16px;
  text-align: left;
  line-height: 24px;
  font-weight: 400;
}

.registered-already {
  width: 456px;
  height: 48px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  margin-bottom: 6px;
}

label[for="firstName"] {
  margin-bottom: 12px;
}

label[for="lastName"] {
  margin-top: 16px !important;
  margin-bottom: 12px;
}

label[for="email"] {
  margin-top: 16px !important;
  margin-bottom: 12px;
}

label[for="password"] {
  margin-top: 16px !important;
  margin-bottom: 12px;
}

label[for="repeatPassword"] {
  margin-top: 16px !important;
  margin-bottom: 12px;
}

label {
  font-size: 16px;
  margin-top: 22px;
  line-height: 24px;
  font-weight: 700;
  font-family: "Open Sans";
  font-style: normal;
  color: #333333;
}

.form-group {
  text-align: left;
}

.form-control {
  box-sizing: border-box;
  height: 64px;
  max-width: 456px;
  background: #ffffff;
  border: 1px solid #7a7a7a;
  border-radius: 4px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  color: #333333;
}

.form-control:focus {
  box-shadow: none;
  border-color: #7a7a7a;
}

.form-control:hover {
  border-color: #1a6edb;
}

.form-check {
  text-align: left;
  color: #030303;
  font-family: Lato;
  font-size: 18px;
}

.password-val {
  color: #666666;
  font-family: Lato;
  font-size: 18px;
  margin-top: 8px;
}

.form-check-label {
  margin-left: 8px;
  margin-top: 16px !important;
  color: #333333;
  font-size: 16px;
  font-weight: 400;
}

.error {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #fc4852;
  position: static;
  text-align: left;
}

input[aria-required="true"] {
  border-color: red !important;
}

input[type="checkbox" i] {
  height: 24px;
  width: 24px;
  border: 1px solid #c2c6cc !important;
  border-radius: 4px;
  margin-top: 16px;
  opacity: 0.85;
}

.recaptcha {
  margin-top: 34px;
}

#tos {
  color: #277ae6;
  font-size: 16px;
  font-weight: bold;
  font-weight: 700;
}

.create-btn-div {
  display: flex;
  justify-content: center;
}

.create-acct-btn {
  width: 222px;
  height: 60px;
  background: #c24c21;
  border: 2px solid #c24c21;
  border-radius: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  margin-bottom: 32px;
  margin-top: 14px;
  gap: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.create-acct-btn:hover {
  background: #ffffff;
  border: 2px solid #c24c21;
  color: #c24c21;
}

.create-acct-btn:active {
  background: #a9431d !important;
  border: 2px solid #a9431d !important;
}

.create-acct-btn:focus {
  box-shadow: none !important;
}

.login-btn {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  text-decoration-line: underline;
  color: #277ae6;
  display: flex;
  justify-content: center;
}

input[aria-invalid="true"].firstName-input.form-control {
  border-color: red !important;
}

@media (min-width: 769px) {
  #registration-form {
    max-width: 584px;
  }

  #registration-form #Alert {
    width: 445px;
    margin: auto;
  }

  .login-btn {
    color: #1a6edb;
    font-family: Lato;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    #registration-header {
      margin-right: 71px;
      font-weight: bold;
      float: left;
      margin-top: 69px;
      margin-bottom: 8px;
    }

    .form-group > label {
      float: left;
      color: #333333;
    }
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  //tablet
  #registration-form {
    max-width: 664px !important;
    min-height: 728px !important;
    background-color: #fff;
  }

  #registration-content {
    max-width: 664px !important;
    padding-left: 16px;
    padding-right: 16px;
  }

  #center {
    max-width: 450px;
    margin-top: 64px;
  }

  .container-sm {
    background-color: transparent;
  }

  h1#registration-header {
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 24px !important;
    line-height: 36px !important;
    text-align: left;
    margin-bottom: 8px;
  }

  .form-group > label {
    display: block;
    text-align: left;
    color: #333333;
  }
}

@media (min-width: 500px) and (max-width: 598px) {
  .registered-already {
    margin-bottom: 27px;
    max-width: 395px;
    margin-left: -16px;
    display: contents;
  }
}

@media (min-width: 360px) and (max-width: 499px) {
  h1#registration-header {
    font-family: "Merriweather";
    font-style: normal;
    font-weight: 700;
    font-size: 24px !important;
    line-height: 36px !important;
    float: left;
    margin-top: 32px;
    margin-bottom: 16px;
  }

  #registration-content {
    max-width: 344px;
    margin-top: 24px;
    margin-bottom: 64px;
  }

  #registration-form {
    max-width: 344px !important;
    padding-bottom: 32px;
    margin-top: 24px;
    margin-left: auto;
    margin-right: auto;
  }

  .registration-setup p {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  input[type="checkbox" i] {
    height: 20px;
    width: 20px;
    margin-top: 18px;
  }

  .form-check-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }

  .registered-already {
    width: 312px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  .login-btn {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    text-decoration-line: none;
  }

  #center {
    margin-right: 16px;
    margin-left: 16px;
  }
}

// @media (max-width: 375px) {
//   //mobile
//   #registration-form {
//     max-width: 344px !important;
//     min-height: 1378px !important;
//   }

//   #registration-content {
//     max-width: 344px !important;
//   }

//   #center {
//     max-width: 100% !important;
//     margin-top: 40px;
//   }

//   p {
//     font-size: 16px;
//   }

//   .form-group > label {
//     float: left;
//     color: #333333;
//   }
// }
</style>
