<template>
  <div>
    <HeaderPublic></HeaderPublic>
    <div id="confirmation-content" class="container-sm mt-sm-5">
      <div id="confirmation">
        <div class="col-2"></div>
        <div id="center" class="col-8">
          <h1 class="confirmation-header">Registration confirmation</h1>
          <div class="confirmation-message">
            <b>Congrats!</b>
            <div style="margin-bottom: 26px">
              Your account was successfully created.
            </div>

            <b>Please check your email</b> and follow the instructions for
            completing your registration.

            <div style="margin-bottom: 26px">
              If you don’t see it, you may need to check your spam folder.
            </div>

            <div>
              Still can’t find the email?<b
                ><a
                  href="mailto:TexasOnCourseSupport@highered.texas.gov?subject=Tomorrow Ready Texas Support Request"
                  class="contact-link"
                >
                  Contact us</a
                ></b
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import HeaderPublic from "../components/HeaderPublic.vue";
export default {
  components: { HeaderPublic },
  name: "Confirm",
  title: "Registration confirmed | Tomorrow Ready Texas",

  data() {
    return {
      lang_stanza_key: "article",
      lang_stanza: {},
      lang_info: {},
    };
  },

  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    document.body.style.backgroundColor = "#F5F7FA";
  },
  methods: {
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    langLookup(key) {
      if (!key) {
        return null;
      }
      let lang_value = this.lang_stanza[key];
      if (!lang_value) {
        lang_value = key;
      }
      return lang_value;
    },
  },
};
</script>

<style lang="scss" scoped>
#center {
  margin-right: auto;
  margin-left: auto;
}

#confirmation {
  max-width: 664px !important;
  min-height: 510px !important;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
}

.confirmation-header {
  margin-bottom: 39px;
  font-size: 40px;
  font-weight: bold;
  float: left;
  margin-top: 69px;
  color: #000000;
}

.confirmation-message {
  text-align: left;
  font-family: Lato;
  font-size: 18px;
  line-height: 28px;
  color: #666666;
}

.confirmation-message > b:nth-child(1) {
  color: #00b258;
}

.contact-link {
  color: #1a6edb;
  margin-left: 2px;
}

@media (min-width: 769px) {
  #confirmation-content {
    border-radius: 10px;
    max-width: 664px !important;
    min-height: 510px !important;
  }

  .confirmation-header {
    margin-right: 38px;
    white-space: nowrap;
  }
}

@media (min-width: 376px) and (max-width: 768px) {
  //tablet
  #confirmation-content {
    margin-top: 50px;
    border-radius: 10px;
    max-width: 664px !important;
    min-height: 510px !important;
  }

  .col-8 {
    max-width: 80%;
  }
}

@media (max-width: 375px) {
  //mobile
  #confirmation-content {
    border-radius: 10px;
    margin: 50px 16px;
    max-width: 344px;
  }

  #confirmation {
    max-width: 344px !important;
  }

  .confirmation-header {
    font-size: 24px;
    margin-right: 38px;
    white-space: nowrap;
  }

  .confirmation-message {
    font-size: 16px;
  }

  .col-8 {
    max-width: 100%;
  }
}
</style>
