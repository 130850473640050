<template>
  <div>
    <Header> </Header>
    <div id="error-content" class="error-container">
      <div>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <h1 class="error-user">{{ error_message_title }}</h1>
        <p class="error-blurb">{{ error_message_exist }}</p>
        <p class="error-click">
          <a href="home">Click here</a>&nbsp;{{ error_message_click }}
        </p>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { grades } from "../_data/grade-data.json";
import { router, sessionStorage } from "../_helpers";
import GTM from "../_helpers/gtm";

export default {
  title: "Page Not Found",
  name: "Error",
  data() {
    return {
      first_name: "",
      error_message_title: "",
      error_message_exist: "",
      error_message_click: "",
      lang_stanza_key: "error",
      lang_stanza: {},
      lang_info: {},
    };
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    if (this.lang_info.lang_code === "en") {
      document.documentElement.setAttribute("lang", "en");
    } else {
      document.documentElement.setAttribute("lang", "es");
    }

    let oidc_id;
    let sessionUserProfile = sessionStorage.decodeUserSession();
    if (!sessionUserProfile || !sessionUserProfile.sub) {
      await router.push("`/${lang_info.lang_code}/landing`");
      return;
    }
    oidc_id = sessionUserProfile.sub;

    await this.getUserProfileByOidc(oidc_id);

    this.error_message_title = `${this.lang_stanza.error}`;
    this.error_message_exist = `${this.lang_stanza.exist}`;
    this.error_message_click = `${this.lang_stanza.click}`;

    await this.getUserPreferences({
      oidc_id: oidc_id,
      lang_code: this.lang_info.lang_code,
    });

    // if (!this.user_grade_pathway_list || this.user_grade_pathway_list.length < 1) {
    await this.getUserGradePathwayList({
      oidc_id: oidc_id,
      lang_code: this.lang_info.lang_code,
    });
    if (!this.user_grade_pathway_list) {
      await this.getUserGradePathwayList({
        oidc_id: oidc_id,
        lang_code: this.lang_info.lang_code,
      });
    }
    this.gp_filtered_list = [];
    this.user_grade_pathway_list.forEach((grade) => {
      grade.pathway_list.forEach((pathway) => {
        if (pathway.is_pathway_selected) {
          let gp_item = {};
          gp_item.grade_key = grade.grade_key;
          gp_item.grade_title = grade.grade_title;
          gp_item.grade_text_suffix = grade.grade_text_suffix;
          gp_item.pathway_key = pathway.pathway_key;
          gp_item.pathway_title = pathway.pathway_title;
          gp_item.grade_text = grade.grade_text;
          gp_item.grade_pathway_id = pathway.grade_pathway_id;
          gp_item.grade_pathway_title = pathway.grade_pathway_title;
          this.gp_filtered_list.push(gp_item);
        }
      });
    });

    //  if (!this.user_grade_pathway_list || this.user_grade_pathway_list.length < 1) {
    //  }

    document.body.style.backgroundColor = "#F5F7FA";
  },
  methods: {
    ...mapActions("preferences", [
      "getUserPreferences",
      "getUserGradePathwayList",
    ]),
    ...mapActions("account", ["getUserProfileByOidc"]),
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    //Imports Grades from local store and filters preferences by grade
    organizeUserGradePathwayList() {
      let userGradePathwayList = [];
      grades.forEach((grade) => {
        let user_grade_pathway_list = this.user_grade_pathway_list.filter(
          (x) => x.grade_key === grade.grade_key
        );
        if (user_grade_pathway_list.length > 0) {
          user_grade_pathway_list.forEach((item) => {
            item.pathways.forEach((pathway) => {
              let new_item = {};
              Object.assign(new_item, item);
              delete new_item.pathways;
              new_item["pathway_id"] = pathway.pathway_id;
              new_item["pathway_key"] = pathway.pathway_key;
              new_item["pathway_title"] = pathway.pathway_title;
              new_item["grade_pathway_id"] = pathway.grade_pathway_id;
              new_item["grade_pathway_title"] = pathway.grade_pathway_title;
              userGradePathwayList.push(new_item);
            });
          });
        }
      });
      return userGradePathwayList;
    },
    //Looks for Pathway title and renders image accordingly
    switchImage(grade_key, pathway_key) {
      return `${require("../assets/" +
        grade_key +
        "_" +
        pathway_key +
        ".png")}`;
    },
  },
  computed: {
    ...mapState("preferences", ["user_grade_pathway_list"]),
    ...mapState("account", ["userProfile"]),
    userState() {
      this.refresh;
      let user = {};
      user = { profile: this.userProfile, status: this.status };
      return user;
    },
    /*
    gpList() {
      this.refresh;
      let sessionUserProfile = sessionStorage.decodeUserSession();
      this.getUserGradePathwayList(sessionUserProfile.sub);
      // if (!this.user_grade_pathway_list || this.user_grade_pathway_list.length < 1) {
      //   this.getUserGradePathwayList(sessionUserProfile.sub);
      // }
      let filtered_gp_list = this.user_grade_pathway_list.filter(grade_pathway => {
        let new_pathway_list = grade_pathway.pathway_list.filter(pathway => {

          if (pathway.is_pathway_selected) {
              return pathway;
          }
        });
        if (new_pathway_list.length > 0) {
          grade_pathway.pathway_list = new_pathway_list;
          return grade_pathway;
        }
      });
      return filtered_gp_list
    },
     */
  },
};
</script>


<style lang="scss" scoped>
.error-container {
  margin: 100px auto;
  text-align: left;
  background-color: #fff;
  border-radius: 10px;
  max-width: 664px;
  padding: 100px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
}

.error-user {
  color: #030303;
  font-family: Lato;
  font-size: 56px;
}

.error-blurb {
  color: #030303;
  font-family: Lora;
  font-size: 20px;
  margin-top: 4px;
  margin-bottom: 50px;
}

.error-click {
  color: #030303;
  font-family: Lora;
  font-size: 20px;
  margin-top: 4px;
  margin-bottom: 65px;
}

@media (max-width: 694px){
  .error-container{
    margin: 100px 15px;
  }
}

@media (min-width: 250px) and (max-width: 499px) {
  .error-container{
    padding: 100px 50px;
  }
}
</style>

