<template>
  <div>
    <Header> </Header>
    <div class="onboarding-content container-sm">
      <div id="onboarding-form">
        <div class="col-2"></div>
        <div id="center" class="col-8">
          <div id="onboarding-header">
            <h1 class="about-title">Tell us about yourself</h1>
          </div>
          <h2>Which school does your teen attend?</h2>
          <p>You can add multiple districts and schools.</p>
          <ValidationObserver v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)" class="form-group">
              <school-district-selection> </school-district-selection>
              <h2 class="content-question">
                What types of action plans do you want to see?
              </h2>
              <label for="grade-pathway"
                >Choose topics you’d like to learn about based on your teens’
                grade level. You can change them in your account settings.
              </label>
              <grade-selection> </grade-selection>

              <!-- <b-form-group class="rectangle">
            <label for="disabilities-content" class="disabilities-content"
              >The site offers additional resources for students with disabilities</label>
            <b-form-checkbox
              id="disabilities-check"
              class="yes"
              v-model="userPreferences.disabilityResources"
              >Yes, I am interested</b-form-checkbox
            >
          </b-form-group> -->
              <b-form-group class="reminders-checkbox-onboarding">
                <b-form-checkbox
                  value="true"
                  id="email-check"
                  class="email-check"
                  v-model="userPreferences.emailNotifications"
                  >Send me reminders for my action plans!</b-form-checkbox
                >
                <h2 class="reminder-h2">
                  These emails help you stay on top of important next steps.
                </h2>
              </b-form-group>
              <div
                id="Alert-prof"
                tabindex="-1"
                v-if="alert"
                :class="'alert ' + alert.type"
              >
                {{ alert.message }}
              </div>
              <div id="center-btn">
                <button
                  type="submit"
                  class="btn btn-primary set-preferences-btn"
                >
                  <span v-show="!loading">Save your settings</span>
                  <div
                    v-show="loading"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span class="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </form>
          </ValidationObserver>
          <div class="col-2"></div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { validation } from "../_helpers/validation";
import GradeSelection from "../components/GradeSelection";
import SchoolDistrictSelection from "../components/SchoolDistrictSelection";
import { router, sessionStorage } from "../_helpers";
import dateformat from "dateformat";

export default {
  title: "Your preferences | Tomorrow Ready Texas",
  name: "Onboarding",
  data() {
    return {
      userPreferences: {
        emailNotifications: true,
        disabilityResources: false,
      },
      loading: false,
      lang_stanza_key: "onboarding",
      lang_stanza: {},
      lang_info: {},
      submit_error_list: [],
    };
  },
  components: {
    GradeSelection,
    SchoolDistrictSelection,
    //ValidationProvider,
    ValidationObserver,
  },
  async created() {
    this.lang_info = await this.getLangInfo();
    this.lang_stanza = await this.getLangStanza({
      lang_code: this.lang_info["lang_code"],
      lang_stanza_key: this.lang_stanza_key,
    });

    if (this.lang_info.lang_code === "en") {
      document.documentElement.setAttribute("lang", "en");
    } else {
      document.documentElement.setAttribute("lang", "es");
    }

    await this.loadData();
    document.body.style.backgroundColor = "#F5F7FA";
  },
  methods: {
    ...mapActions("preferences", [
      "getGrades",
      "setUserPreferences",
      "persist_user_schools",
      "getUserPreferences",
    ]),
    ...mapActions("account", [
      "updateUserProfile",
      "getUserProfileByOidc",
      "persistDateUpdate",
    ]),
    ...mapActions("registration", ["logout"]),
    ...mapActions("language", ["getLangInfo", "getLangStanza"]),
    ...mapActions("alert", ["clear", "error", "success"]),
    langLookup(key) {
      if (!key) {
        return null;
      }
      let lang_value = this.lang_stanza[key];
      if (!lang_value) {
        lang_value = key;
      }
      return lang_value;
    },
    async onSubmit() {
      this.submitted = true;
      this.loading = true;

      this.submit_error_list = [];

      if (this.user_school_selections.length < 1) {
        this.submit_error_list.push({
          error_key: "insufficient-selections",
          error_message: this.langLookup("err_no_district_school"),
        });
        await this.error(this.langLookup("err_no_district_school"));
        this.submitted = false;
        this.loading = false;
        return;
      }

      if (this.user_pathway_selections.length < 1) {
        this.submit_error_list.push({
          error_key: "insufficient-selections",
          error_message: this.langLookup("err_no_grade_pathway"),
        });
        await this.error(this.langLookup("err_no_grade_pathway"));
        this.submitted = false;
        this.loading = false;
        return;
      }
      // if (this.submit_error_list.length > 0) {
      //   this.submit_error_list.push({
      //     error_key: "errors-exist",
      //     error_message: this.langLookup("err_errors_exist"),
      //   });
      //   await this.error(this.langLookup("err_errors_exist"));

      //   this.submitted = false;
      //   this.loading = false;
      //   return;
      // }

      await this.completeOnboarding();
      router.push(`/${this.lang_info.lang_code}/home`);
    },
    async completeOnboarding() {
      if (this.submit_error_list.length > 0) {
        this.submit_error_list.push({
          error_key: "errors-exist",
          error_message: "Errors exist. Profile and preferences were not saved",
        });
        return;
      }

      let sessionUserProfile = await sessionStorage.decodeUserSession();
      if (!sessionUserProfile || !sessionUserProfile.sub) {
        this.logout();
      }
      const oidc_id = sessionUserProfile.sub;
      const params = `is_active = 'true',
                      is_disability_interest = '${this.userPreferences.disabilityResources}',
                      is_email_sub = '${this.userPreferences.emailNotifications}',
                      is_tos_acceptance = 'false'`;
      //Updates disability check and 'is_active' in DB
      await this.updateUserProfile({ oidc_id: oidc_id, params: params });
      await this.setUserPreferences({
        oidc_id: oidc_id,
        lang_code: this.lang_info.lang_code,
        districts: this.user_district_selections,
        schools: this.user_school_selections,
        user_pathway_selections: this.user_pathway_selections,
      });
      await this.persist_user_schools({
        oidc_id: oidc_id,
        user_schools: this.user_school_selections,
      });

      let now = new Date();
      let ts_update = dateformat(now, "yyyy-mm-dd HH:MM:ss.l");

      let packet_dict = {
        oidc_id: sessionUserProfile.sub,
        ts_update: ts_update,
      };
      await this.persistDateUpdate(packet_dict);
    },
    //Runs a check to see if user is still logged in
    async loadData() {
      let sessionUserProfile = await sessionStorage.decodeUserSession();
      if (!sessionUserProfile || !sessionUserProfile.sub) {
        this.logout();
      }
      // await this.getUserProfileByOidc(sessionUserProfile.sub);
      // this.userPreferences.emailNotifications = this.userProfile.is_email_sub;
      // await this.getGrades();
      /*
      if (! this.user_grade_pathway_list || this.user_grade_pathway_list.length < 1) {
        let userSessionProfile = sessionStorage.decodeUserSession();
        await this.getUserGradePathwayList(userSessionProfile.sub);
      }
      this.selectedPathways = [];
      this.user_grade_pathway_list.forEach(user_grade_pathway => {
            this.selectedPathways.push(pathway.grade_pathway_id);
      })
       */
    },
  },
  computed: {
    ...mapState("preferences", [
      "grades",
      "user_grade_pathway_list",
      "user_pathway_selections",
      "user_district_selections",
      "user_school_selections",
    ]),
    ...mapState("account", ["userProfile"]),
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
};
</script>

<style lang="scss">
.about-title {
  font-family: "Merriweather";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #000000;
}

.onboarding-content {
  margin-top: 116px;
}

.container-sm {
  max-width: 584px !important;
  //margin-top: 22px;
  margin-bottom: 100px !important;
}

#onboarding-form {
  max-width: 584px;
  min-height: 720px !important;
  border-radius: 10px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  padding-bottom: 1px;
}
#onboarding-header {
  padding-top: 64px;
  text-align: left;
  margin-bottom: 4px;
}

h2 {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: #000000;
  text-align: left;
  margin-bottom: 4px;
  margin-top: 32px;
}

#center p {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}

.content-question {
  white-space: nowrap;
  font-size: 18px;
  line-height: 28px;
  font-weight: 700;
  font-style: normal;
}
.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}

.dropdown-item {
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: default;
}

.dropdown-item:hover,
.dropdown-item:focus {
  background-color: transparent !important;
}

// .dropdown-input:focus,
// .dropdown-selected:hover {
//   background: #fff;
//   border-color: #e2e8f0;
// }
.dropdown-input::placeholder {
  opacity: 0.7;
}

.dropdown-input,
.dropdown-selected {
  width: 100%;
  padding: 10px 16px;
  border: 1px solid lightgray;
  background: white;
  line-height: 1.5em;
  outline: none;
  text-align: left;
  margin-bottom: 20px;
}
.dropdown-input:focus,
.dropdown-selected:hover {
  background: #fff;
  border-color: #e2e8f0;
}
.dropdown-input::placeholder {
  opacity: 0.7;
}
.dropdown-selected {
  font-weight: bold;
  cursor: pointer;
}

.dropdown-list {
  position: absolute;
  z-index: 10;
  width: 100%;
  max-height: 500px;
  margin-top: -32px;
  overflow-y: inherit;
  background: #ffffff;
  // box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  //   0 4px 6px -2px rgba(0, 0, 0, 0.05);
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-start;
  // display: flex;
  // flex-direction: column;
  // align-items: flex-start;
  // padding: 16px;
  // gap: 16px;
  // position: absolute;
  // left: 0%;
  // right: 0%;
  // top: 36%;
  // bottom: 2%
}

.dropdown.list > * {
  flex: 1 1 33%;
}
.dropdown-item {
  display: flex;
  width: 100%;
  padding: 11px 16px;
  cursor: default;
}

.caret {
  font-weight: bolder;
  font-size: 10pt;
  color: gray;
  float: right;
  margin: 0;
  padding: 0;
}
.vs__dropdown-menu {
  max-width: 100% !important;
}

label {
  color: #333333;
  font-family: Lato;
  font-size: 14px;
}

.district-selection {
  color: red;
  // max-width: 450px;
  //margin-top: 29px;
}

label .district-selection {
  margin-top: 0px;
}

label {
  color: #333333;
  font-family: Lato;
  font-size: 14px;
  margin-top: 29px;
}

label[for="grade-pathway"] {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  color: #000000;
  // margin-top: 21px;
  margin-bottom: 32px;
}

label[for="pathway-selection-input"] {
  font-size: 14px;
  cursor: pointer;
}

p {
  color: #666666;
  font-family: Lato;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-style: normal;
  //margin-top: 21px;
  margin-bottom: 0px;
  text-align: left;
}

.set-preferences-btn {
  width: 246px;
  height: 60px;
  background: #c24c21;
  border: 2px solid #c24c21;
  border-radius: 16px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 48px;
  margin-top: 16px;
}

.form-control:focus {
  box-shadow: none;
  border-color: #7a7a7a;
}

.form-control:hover {
  border-color: #1a6edb;
}

// .reminders-checkbox-onboarding {
//   border-radius: 6px;
//   background-color: #f5f7fa;
//   padding: 16px 22px 29px 22px;
//   margin-top: 24px;
//   min-height: 155px;
//   max-width: 448px;
// }

.reminder-h2 {
  margin-top: 0px;
}

input[type="checkbox" i] {
  cursor: pointer;
}

input[type="checkbox" i]#disabilities-check {
  width: 24px;
  height: 24px;
  opacity: 0.65;
}

input[type="checkbox" i]#email-check {
  width: 24px;
  height: 24px;
  // opacity: 0.65;
}

.set-preferences-btn:hover {
  background: #ffffff;
  border: 2px solid #c24c21;
  color: #c24c21;
}

.set-preferences-btn:active {
  background: #a9431d !important;
  border: 2px solid #a9431d !important;
}

.set-preferences-btn:focus {
  background-color: #f15a2a;
  border-color: #f15a2a;
  box-shadow: none !important;
}

.yes .custom-control-label,
.email-check .custom-control-label {
  margin-left: 8px;
  margin-top: -5px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  font-style: normal;
  padding-top: 2px;
}

.email-check {
  // margin-top: 31px;
  //font-size: 40px;
}

// .reminders-checkbox-onboarding {
//   border-radius: 6px;
//   // background-color: #E6E9ED;
//   padding: 16px 22px 29px 22px;
//   margin-top: 24px;
//   min-height: 155px;
//   max-width: 448px;
// }

.reminder-h2 {
  margin: 8px 10px 0 0px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #030303;
}
.dropdown {
  position: relative;
  width: 100%;
}

.district-selection {
  color: red;
}

#error-area {
  margin-bottom: 100px;
}

#center-btn {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}

.custom-control-label::before {
  display: none !important;
}

.custom-control-label::after {
  display: none !important;
}

.rectangle {
  height: 120px;
  width: 448px;
  border-radius: 6px;
  background-color: #f5f7fa;
  padding: 16px 22px;
  margin-bottom: -1px;
  margin-top: 18px;
}

.disabilities-content {
  color: #3a3a3a;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 13px;
}

.yes .custom-control-label,
.email-check .custom-control-label {
  margin-left: 8px;
  margin-top: -2px !important;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #030303;
  float: left;
}

@media (min-width: 769px) {
  .onboarding-content {
    border-radius: 10px !important;
    min-width: 584px !important;
  }

  #onboarding-form {
    min-width: 584px !important;
  }

  #center {
    margin-right: auto;
    margin-left: auto;
    max-width: 486px;
  }

  .content-question {
    white-space: nowrap;
  }

  .set-preferences-btn {
    width: 229px;
  }

  .number-selected {
    left: 270px !important;
  }
  
}

@media (max-width: 768px) {
  #grade-dropdown-8 {
    display: inline;
  }
  #grade-dropdown-9 {
    display: inline;
  }
  #grade-dropdown-10 {
    display: inline;
  }
  #grade-dropdown-11 {
    display: inline;
  }
  #grade-dropdown-12 {
    display: inline;
  }
  .number-selected {
    display: inline;
    position: inherit !important;
    // float: right;
  }
  .arrow {
    margin-top: 4px !important;
  }
}

@media (min-width: 500px) and (max-width: 768px) {
  // .yes .custom-control-label,
  // .email-check .custom-control-label {
  //   margin-left: -62.3px !important;
  // }
}

@media (min-width: 376px) and (max-width: 768px) {
  //tablet
  .onboarding-content {
    border-radius: 10px !important;
    //margin-top: 60px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .container-sm {
    max-width: 584px !important;
    margin-left: auto;
    margin-right: auto;
    background-color: transparent;
  }

  #onboarding-form {
    background-color: #fff;
    // max-width: 584px !important;
  }

  #center {
    margin-right: auto;
    margin-left: auto;
    max-width: 450px;
  }

  .content-question {
    white-space: nowrap;
    margin-top: 173px;
  }

  label[for="grade-pathway"] {
    text-align: left;
  }

  .yes .custom-control-label,
  .email-check .custom-control-label {
    margin-top: -2px !important;
  }

  // .dropdown-list {
  //   position: absolute;
  //   z-index: 2;
  //   width: 100%;
  //   height: 136px;
  //   overflow-y: auto;
  //   background: #ffffff;
  //   box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  //     0 4px 6px -2px rgba(0, 0, 0, 0.05);
  //   font-size: 14px;
  //   padding: 16px 16px 16px 16px;
  //   margin: -30px 0 0 0;
  //   border-width: 0px 1px 1px 1px;
  //   border-style: solid;
  //   border-color: #B3B3B3;
  //   border-radius: 0px 0px 4px 4px;
  // }
}

@media (min-width: 376px) and (max-width: 560px) {
  //tablet
  .content-question {
    white-space: normal;
    margin-top: 175px !important;
  }
}

@media (min-width: 376px) and (max-width: 390px) {
#pathway-checkbox-group:nth-child(1) {
  position: relative;
    width: 10px;
    left: 0px;
  }
  #pathway-checkbox-group:nth-child(2) {
    position: relative;
    left: 80px;
    top: 2px;
  }

  #pathway-checkbox-group:nth-child(3) {
    position: relative;
    left: 11px;
  }

  #pathway-checkbox-group:nth-child(4) {
    position: relative;
    left: -11px;
  }

  #pathway-checkbox-group:nth-child(5) {
    position: relative;
    left: 11px;
  }

}

@media (min-width: 391px) and (max-width: 393px) {
#pathway-checkbox-group:nth-child(1) {
  position: relative;
    width: 10px;
    left: 0px;
  }
  #pathway-checkbox-group:nth-child(2) {
    position: relative;
    left: 80px;
    top: 2px;
  }

  #pathway-checkbox-group:nth-child(3) {
    position: relative;
    left: 9px;
  }

  #pathway-checkbox-group:nth-child(4) {
    position: relative;
    left: -11px;
  }

  #pathway-checkbox-group:nth-child(5) {
    position: relative;
    left: 9px;
  }

}

@media (min-width: 394px) and (max-width: 412px) {
#pathway-checkbox-group:nth-child(1) {
  position: relative;
    width: 10px;
    left: 0px;
  }
  #pathway-checkbox-group:nth-child(2) {
    position: relative;
    left: 80px;
    top: 2px;
  }

  #pathway-checkbox-group:nth-child(3) {
    position: relative;
    left: -4px;
  }

  #pathway-checkbox-group:nth-child(4) {
    position: relative;
    left: -11px;
  }

  #pathway-checkbox-group:nth-child(5) {
    position: relative;
    left: -4px;
  }

}

@media (min-width: 413px) and (max-width: 414px) {
#pathway-checkbox-group:nth-child(1) {
  position: relative;
    width: 10px;
    left: 0px;
  }
  #pathway-checkbox-group:nth-child(2) {
    position: relative;
    left: 80px;
    top: 2px;
  }

  #pathway-checkbox-group:nth-child(3) {
    position: relative;
    left: -5px;
  }

  #pathway-checkbox-group:nth-child(4) {
    position: relative;
    left: -11px;
  }

  #pathway-checkbox-group:nth-child(5) {
    position: relative;
    left: -5px;
  }

}

@media (min-width: 360px) and (max-width: 499px) {
  //mobile

  // .onboarding-content {
  //   margin-left: 8px;
  //   margin-right: 8px;
  // }
  .about-title {
    font-size: 24px;
    line-height: 36px;
  }

  .onboarding-content {
    margin-top: 24px;
    border-radius: 10px !important;
    max-width: 344px;
  }

  .container-sm {
    margin-top: 24px !important;
    margin-bottom: 64px !important;
  }

  #onboarding-form {
    min-width: 344px !important;
  }

  #onboarding-header {
    padding-top: 32px;
    text-align: left !important;
  }

  #center {
    margin-right: auto;
    margin-left: auto;
  }

  #center p {
    font-size: 14px;
    line-height: 24px;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
  }

  .content-question {
    margin-top: 32px;
    font-size: 16px;
    line-height: 24px;
    white-space: normal;
    width: 312px;
  }

  label[for="grade-pathway"] {
    text-align: left;
  }

  // .dropdown-list {
  //   left: -15px;
  //   width: 110%;
  // }

  label[for="grade-pathway"] {
    font-size: 14px;
    line-height: 24px;
    text-align: left;
  }

  .col-8 {
    max-width: 100%;
  }
}

@media (min-width: 360px) and (max-width: 499px) {
  .yes .custom-control-label,
  .email-check .custom-control-label {
    float: left !important;
    margin-left: 8px !important;
    text-align: left;
  }
}
</style>
